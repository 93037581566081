import { useEffect } from 'react';

const Drip = () => {
  useEffect(() => {
    window.location.replace(`https://mainframe.genopets.me/drip`);
  }, []);
  return null;
};

export default Drip;
